import axios from "axios";
const URL = "https://api.tangentmediastocks.com";

export const login = async (data) => {
  const { data: res } = await axios.post(`${URL}/admin/login`, data);
  return res;
};
export const getAllUploads = async () => {
  const { data } = await axios.get(`${URL}/admin/uploads`);
  return data;
};

export const updateUpload = async (id, data2) => {
  const { data } = await axios.put(`${URL}/admin/upload/${id}`, data2);
  return data;
};
export const addUserNotification = async (data) => {
  const response = await axios.post(`${URL}/admin/notification`, data);
  return response.data;
};
export const getAllAdmins = async () => {
  const { data: res } = await axios.get(`${URL}/admin/all-admin`);
  return res;
};
export const getUsers = async (page) => {
  const { data } = await axios.get(`${URL}/admin/users?page=${page}&limit=20`);
  return data;
};
export const getDash = async () => {
  const { data } = await axios.get(`${URL}/admin/dashboard`);
  return data;
};
export const upload = async (data2) => {
  const { data } = await axios.post(`${URL}/upload`, data2);
  return data;
};
export const userSerach = async (data) => {
  const response = await axios.post(`${URL}/admin/user-search`, data);
  return response.data;
};
export const deleteAdmin = async (id) => {
  const { data } = await axios.delete(`${URL}/admin/delete-admin/${id}`);
  return data;
};
export const updateAdmin = async (id, data2) => {
  const { data } = await axios.put(`${URL}/admin/admin-update/${id}`, data2);
  return data;
};
export const addUser = async (data2) => {
  const { data } = await axios.post(`${URL}/admin/user`, data2);
  return data;
};

export const putsystemval = async (data2) => {
  const { data } = await axios.put(`${URL}/admin/systemvalues`, data2);
  return data;
};

export const getSystemValues = async () => {
  const { data } = await axios.get(`${URL}/systemvalues`);
  return data;
};

export const getCategories = async () => {
  const { data } = await axios.get(`${URL}/admin/categories`);
  return data;
};

export const addCategory = async (data2) => {
  const { data } = await axios.post(`${URL}/admin/category`, data2);
  return data;
};

export const deleteCategory = async (id) => {
  const { data } = await axios.delete(`${URL}/admin/category/${id}`);
  return data;
};

export const updateCategory = async (id, data2) => {
  const { data } = await axios.put(`${URL}/admin/category/${id}`, data2);
  return data;
};
export const addPhotos = async (data2) => {
  const { data } = await axios.post(`${URL}/admin/photo`, data2);
  return data;
};

export const getPhotos = async () => {
  const { data } = await axios.get(`${URL}/admin/photos`);
  return data;
};
export const addAdmin = async (data2) => {
  const { data } = await axios.post(`${URL}/admin/signup`, data2);
  return data;
};

export const deletePhoto = async (id) => {
  const { data } = await axios.delete(`${URL}/admin/photo/${id}`);
  return data;
};

export const updatePhoto = async (id, data2) => {
  const { data } = await axios.put(`${URL}/admin/photo/${id}`, data2);
  return data;
};

export const addVideo = async (data2) => {
  const { data } = await axios.post(`${URL}/admin/video`, data2);
  return data;
};

export const getVideos = async () => {
  const { data } = await axios.get(`${URL}/admin/videos`);
  return data;
};

export const deleteVideo = async (id) => {
  const { data } = await axios.delete(`${URL}/admin/video/${id}`);
  return data;
};

export const updateVideo = async (id, data2) => {
  const { data } = await axios.put(`${URL}/admin/video/${id}`, data2);
  return data;
};

export const getDownlaods = async (id) => {
  const { data } = await axios.get(`${URL}/admin/downloads/${id}`);
  return data;
};

export const getDownloadHistory = async (data2) => {
  const { data } = await axios.post(`${URL}/admin/download-history`, data2);
  return data;
};

export const updateUser = async (id, data2) => {
  const { data } = await axios.put(`${URL}/admin/user/${id}`, data2);
  return data;
};

export const deleteUser = async (id) => {
  const { data } = await axios.delete(`${URL}/admin/user/${id}`);
  return data;
};

export const updateSytstemValues = async (data2) => {
  const { data } = await axios.put(`${URL}/admin/systemvalues`, data2);
  return data;
};

export const getTodayTransactions = async () => {
  const { data } = await axios.get(`${URL}/admin/today-transactions`);
  return data;
};

export const getTransactions = async () => {
  const { data } = await axios.get(`${URL}/admin/transactions`);
  return data;
};

export const getUserPurchaseHistory = async (id) => {
  const { data } = await axios.get(`${URL}/admin/user-purchase/${id}`);
  return data;
};
export const getUserSellHistory = async (id) => {
  const { data } = await axios.get(`${URL}/admin/user-sales/${id}`);
  return data;
};

export const getUserWalletHistory = async (id) => {
  const { data } = await axios.get(`${URL}/admin/user-wallet/${id}`);
  return data;
};

export const getRedeemRequest = async () => {
  try {
    const response = await axios.get(`${URL}/admin/redeem-requests`);
    if (response.status === 200) {
      return [true, response.data];
    } else {
      return [false, response.data];
    }
  } catch (error) {
    return [false, error];
  }
};

export const acceptDenyRedeemRequest = async (id, request) => {
  try {
    const response = await axios.post(
      `${URL}/admin/redeem-request/${id}`,
      request
    );
    if (response.status === 200) {
      return [true, response.data];
    } else {
      return [false, response.data];
    }
  } catch (error) {
    return [false, error];
  }
};
