import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Space,
  Spin,
  Table,
  Tabs,
} from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { acceptDenyRedeemRequest, getRedeemRequest } from "../utils/api";
import { useAuth } from "../utils/store";

const RedeemRequest = () => {
  const navigate = useNavigate();
  const user = useAuth((state) => state.user);
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [isApproveModalVisible, setIsApproveModalVisible] = useState(false);
  const [isDeclineModalVisible, setIsDeclineModalVisible] = useState(false);
  const [currentRequestId, setCurrentRequestId] = useState(null);

  const { data: requests, isLoading } = useQuery({
    queryKey: ["redeemRequests"],
    queryFn: async () => {
      const [success, data] = await getRedeemRequest();
      if (success) return data;
      throw new Error("Failed to fetch requests");
    },
  });

  const mutation = useMutation({
    mutationFn: ({ id, request }) => acceptDenyRedeemRequest(id, request),
    onSuccess: () => {
      queryClient.invalidateQueries(["redeemRequests"]);
      message.success("Request updated successfully");
      setIsApproveModalVisible(false);
      setIsDeclineModalVisible(false);
      form.resetFields();
    },
    onError: () => {
      message.error("Failed to update request");
    },
  });

  const showApproveModal = (id) => {
    setCurrentRequestId(id);
    setIsApproveModalVisible(true);
  };

  const showDeclineModal = (id) => {
    setCurrentRequestId(id);
    setIsDeclineModalVisible(true);
  };

  const handleApprove = () => {
    form.validateFields().then((values) => {
      mutation.mutate({
        id: currentRequestId,
        request: {
          status: "Completed",
          transactionId: values.transactionId,
          userId: currentRequestId,
        },
      });
    });
  };

  const handleDecline = () => {
    form.validateFields().then((values) => {
      mutation.mutate({
        id: currentRequestId,
        request: {
          status: "Decline",
          message: values.message,
          userId: currentRequestId,
        },
      });
    });
  };

  const newRequestColumns = [
    { title: "Created At", dataIndex: "createdAt", key: "createdAt" },
    { title: "Status", dataIndex: "status", key: "status" },
    { title: "User Name", dataIndex: ["user", "name"], key: "userName" },
    { title: "User Email", dataIndex: ["user", "email"], key: "userEmail" },
    { title: "UPI", dataIndex: ["user", "upi"], key: "upi" },
    { title: "Bank Name", dataIndex: ["user", "bankName"], key: "bankName" },
    { title: "IFSC", dataIndex: ["user", "ifsc"], key: "ifsc" },
    {
      title: "Aadhar Card",
      dataIndex: ["user", "aadharCardNumber"],
      key: "aadharCardNumber",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space direction="vertical">
          <Button type="primary" onClick={() => showApproveModal(record._id)}>
            Approve
          </Button>
          <Button danger onClick={() => showDeclineModal(record._id)}>
            Decline
          </Button>
        </Space>
      ),
    },
  ];

  const completedColumns = [
    { title: "Created At", dataIndex: "createdAt", key: "createdAt" },
    { title: "Status", dataIndex: "status", key: "status" },
    { title: "User Name", dataIndex: ["user", "name"], key: "userName" },
    { title: "User Email", dataIndex: ["user", "email"], key: "userEmail" },
    { title: "UPI", dataIndex: ["user", "upi"], key: "upi" },
    { title: "Bank Name", dataIndex: ["user", "bankName"], key: "bankName" },
    { title: "IFSC", dataIndex: ["user", "ifsc"], key: "ifsc" },
    {
      title: "Aadhar Card",
      dataIndex: ["user", "aadharCardNumber"],
      key: "aadharCardNumber",
    },
    {
      title: "Transaction ID",
      dataIndex: "transactionId",
      key: "transactionId",
    },
  ];

  const declinedColumns = [
    { title: "Created At", dataIndex: "createdAt", key: "createdAt" },
    { title: "Status", dataIndex: "status", key: "status" },
    { title: "User Name", dataIndex: ["user", "name"], key: "userName" },
    { title: "User Email", dataIndex: ["user", "email"], key: "userEmail" },
    { title: "UPI", dataIndex: ["user", "upi"], key: "upi" },
    { title: "Bank Name", dataIndex: ["user", "bankName"], key: "bankName" },
    { title: "IFSC", dataIndex: ["user", "ifsc"], key: "ifsc" },
    {
      title: "Aadhar Card",
      dataIndex: ["user", "aadharCardNumber"],
      key: "aadharCardNumber",
    },
    { title: "Decline Reason", dataIndex: "message", key: "message" },
  ];

  React.useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spin size="large" tip="Loading requests..." />
      </div>
    );
  }

  return (
    <div
      style={{
        padding: "1rem",
        maxWidth: "1200px",
        margin: "0 auto",
      }}
    >
      <Tabs
        defaultActiveKey="1"
        items={[
          {
            key: "1",
            label: "New Requests",
            children: (
              <Table
                columns={newRequestColumns}
                dataSource={requests?.created}
                rowKey="_id"
                pagination={{ pageSize: 5 }}
                scroll={{ x: true }}
              />
            ),
          },
          {
            key: "2",
            label: "Completed",
            children: (
              <Table
                columns={completedColumns}
                dataSource={requests?.completed}
                rowKey="_id"
                pagination={{ pageSize: 5 }}
                scroll={{ x: true }}
              />
            ),
          },
          {
            key: "3",
            label: "Declined",
            children: (
              <Table
                columns={declinedColumns}
                dataSource={requests?.decline}
                rowKey="_id"
                pagination={{ pageSize: 5 }}
                scroll={{ x: true }}
              />
            ),
          },
        ]}
      />

      {/* Approve Modal */}
      <Modal
        title="Approve Redeem Request"
        open={isApproveModalVisible}
        onOk={handleApprove}
        onCancel={() => setIsApproveModalVisible(false)}
        confirmLoading={mutation.isLoading}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="transactionId"
            label="Transaction ID"
            rules={[
              { required: true, message: "Please enter the transaction ID" },
            ]}
          >
            <Input placeholder="Enter transaction ID" />
          </Form.Item>
        </Form>
      </Modal>

      {/* Decline Modal */}
      <Modal
        title="Decline Redeem Request"
        open={isDeclineModalVisible}
        onOk={handleDecline}
        onCancel={() => setIsDeclineModalVisible(false)}
        confirmLoading={mutation.isLoading}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="message"
            label="Reason for Declining"
            rules={[
              {
                required: true,
                message: "Please provide a reason for declining",
              },
            ]}
          >
            <Input.TextArea placeholder="Enter reason for declining" rows={4} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default RedeemRequest;
